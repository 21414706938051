import * as Yup from "yup";

export const formDemoValidator = Yup.object().shape({
  fname: Yup.string().required("First name is required."),
  lname: Yup.string().required("Last name is required."),
  email: Yup.string().required("Email is required."),
  netFlix: Yup.bool().required("Netflix field is required."),
  optionFruit: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, "Fruit option is required."),
  startDate: Yup.string().required("Start date is required."),
  endDate: Yup.string().required("End date is required."),
  myTime: Yup.string().required("Time is required."),
});
