import React from "react";
import PropTypes from "prop-types";

const SocialLinks = ({ data, containerCss }) => {
  return (
    <section className={containerCss}>
      {data.map((o, i) => (
        <a
          href={o.url}
          alt={o.alt}
          target="_blank"
          rel="noreferrer"
          className={o.hoverClass ? o.hoverClass : null}
          key={i}
        >
          <i className={`${o.icon} ${o.size}`} />
          {/* <FontAwesomeIcon
            icon={o.icon}
            //color={o.color}
            size={o.size}
            className={o.className}
          /> */}
        </a>
      ))}
    </section>
  );
};

SocialLinks.propTypes = {
  containerCss: PropTypes.string,
  data: PropTypes.array,
};

SocialLinks.defaultProps = { data: [] };

export default SocialLinks;
