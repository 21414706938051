import React from "react";
import { Lightbox } from "react-modal-image";
import PropTypes from "prop-types";

const ImagePreview = ({
  isOpen,
  imageLargeUrl,
  showRotate,
  closePreview,
  alternateText,
}) => {
  return (
    <>
      {isOpen && (
        <Lightbox
          medium={imageLargeUrl}
          showRotate={showRotate}
          alt={alternateText}
          onClose={closePreview}
        />
      )}
    </>
  );
};

ImagePreview.propTypes = {
  isOpen: PropTypes.bool,
  imageLargeUrl: PropTypes.string,
  showRotate: PropTypes.bool,
  closePreview: PropTypes.func,
  alternateText: PropTypes.string,
};

ImagePreview.defaultProps = {};

export default ImagePreview;
