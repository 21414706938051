import SystemReducer from "./system/systemSlice";
import RequestQuotationReducer from "components/containers/requestQuotation/requestQuotationSlice";
import QuotationReducer from "store/modules/quotation-slice";
import CommonDataReducer from "store/modules/commonDataSlice";
import ContactUsReducer from "store/modules/contactUsSlice";

const RootReducer = {
  system: SystemReducer,
  contactUs: ContactUsReducer,
  requestQuotation: RequestQuotationReducer,
  quotation: QuotationReducer,
  commonData: CommonDataReducer,
};

export default RootReducer;
