import { Navigate, Route } from "react-router-dom";
import DemoPage from "pages/demo/DemoPage";
import ButtonPage from "pages/demo/buttons/ButtonPage";
import FramerPage from "pages/demo/framer/FramerPage";
import NavBarPage from "pages/demo/navBar/NavBarPage";
import DashboardPage from "pages/admin/dashboard/DashboardPage";
import InputDemoPage from "pages/demo/forms/InputDemoPage";
import DataTablePage from "pages/demo/table/DataTablePage";
import PlaygroundPage from "pages/demo/playground/PlaygroundPage";

const adminRoutes = () => {
  return (
    <>
      <Route index element={<DashboardPage />} />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="demo" element={<DemoPage />}>
        <Route path="buttons" element={<ButtonPage />} />
        <Route path="navbar" element={<NavBarPage />} />
        <Route path="framer-motion" element={<FramerPage />} />
        <Route path="input-demo" element={<InputDemoPage />} />
        <Route path="data-table" element={<DataTablePage />} />
        <Route path="playground" element={<PlaygroundPage />} />
      </Route>
      <Route path="*" element={<Navigate to="pages/error-404" />} />
    </>
  );
};

export default adminRoutes;
