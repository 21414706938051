import {
  CLIENT_EMAIL,
  FOOTER_PHONE1,
  FOOTER_PHONE2,
} from "constants/appConstants";

const fatFooterData = {
  location: "",
  callUs: [FOOTER_PHONE1, FOOTER_PHONE2],
  emailUs: [CLIENT_EMAIL],
};

export default fatFooterData;
