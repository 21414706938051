import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import provinceCity from "data/provinceAndCity/provinceAndCity.json";
import ProductService from "services/modules/productService";
import * as SystemSlice from "store/system/systemSlice";
import { getProductServiceData, getProductServiceQuoteData } from "./helpers";

const initialState = {
  provinces: [],
  productServices: [],
  productServicesQuoteData: [],
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const loadProvinces = createAsyncThunk(
  "commonData/loadProvinces",
  async (data, thunkAPI) => {
    try {
      return provinceCity;
    } catch (err) {
      throw err;
    }
  }
);

export const loadProductServices = createAsyncThunk(
  "commonData/loadProductServices",
  async (data, thunkAPI) => {
    try {
      const result = await new ProductService().getProductsAsync();

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to load products/services.",
          error: err,
        })
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const commonDataSlice = createSlice({
  name: "commonData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //
    // Load Provinces Service
    //
    builder.addCase(loadProvinces.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadProvinces.fulfilled, (state, action) => {
      state.provinces = action.payload;
      state.loading = false;
    });
    builder.addCase(loadProvinces.rejected, (state) => {
      state.loading = false;
    });

    //
    // Load Product Services
    //
    builder.addCase(loadProductServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadProductServices.fulfilled, (state, action) => {
      state.productServices = getProductServiceData(action.payload);
      state.productServicesQuoteData = getProductServiceQuoteData(
        action.payload
      );
      state.loading = false;
    });
    builder.addCase(loadProductServices.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default commonDataSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.commonData;

export const selectCommonData = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
