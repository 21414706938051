/**
 * Application wide settings
 */

const Config = {
  appVersion: "0.1.0",
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: "4znvqs1qwq93hooqkjyd07cvk6pgkwdq",
  authStorageKey: "rca:auth.key",
  content: {
    allowFx: false, // Note, you need to enable $enableTransitionGroupFx in _variables.scss to take effect
  },
  footer: {
    showFatFooter: true,
    fatFooterType: 4,
    copyrightText:
      "© @year@ <a href='https://rcnetlabs.com' target='_blank'>ABBATOIR</a>. All Rights Reserved. <a href='https://rcnetlabs.com' target='_blank' class='me-1x'>Powered by RcnetLabs.</a> | <a href='/privacy-policy'>Privacy Policy</a>",
  },
  gmap: {
    gmapKey: "AIzaSyByw3GNp5MZ1AoxgIU-Jk7zZBLrn10pt3Y",
    location: {
      address:
        "Abbatoir Pest Control <br/> 263 15th Ave, Cubao, Quezon City, Metro Manila",
      lat: 14.62631602630135,
      lng: 121.05641570466693,
    },
  },
  dateTimeSettings: {
    dateFormat: "",
    isoDateFormat: "YYYY-MM-DDTHH:mm:ss.sssZ",
    shortIsoDateFormat: "YYYY-MM-DD",
    minDate: "01 JAN 1900",
    shortDateTimeFormat: "MM/DD/YYYY HH:mm:ss",
    shortDateFormat: "MM/DD/YYYY",
    monthYearFormat: "MM/YYYY",
    shortTimeFormat: "HH:mm",
  },
  components: {
    blockUi: {
      color: "#000097",
    },
    fbChat: {
      appId: "981465966411578",
      pageId: "100756769521265",
    },
  },
  features: {},
  environmentMode: process.env.NODE_ENV,
};

export default Config;
