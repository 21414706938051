import React from "react";
import Splash from "components/ui/splash/Splash";
import * as QuotationSlice from "store/modules/quotation-slice";
import { useDispatch } from "react-redux";
import * as CommonDataSlice from "store/modules/commonDataSlice";
//import useAuth from "hooks/useAuth";

const SplashLoader = ({ children }) => {
  //const { loadProfileAsync } = useAuth();
  const dispatch = useDispatch();

  const handleDataLoader = () => {
    return new Promise(async (resolve, reject) => {
      try {
        //await loadProfileAsync();
        console.log("User Profile loaded...");

        dispatch(QuotationSlice.loadAllServices());
        dispatch(CommonDataSlice.loadProvinces());
        dispatch(CommonDataSlice.loadProductServices())
          .unwrap()
          .then(() => {
            resolve("User Profile loaded.");
          });

        // setTimeout(() => {
        //   resolve("User Profile loaded.");
        // }, 2000);
      } catch (err) {
        reject(err);
      }
    });
  };

  return <Splash onDataLoad={handleDataLoader}>{children}</Splash>;
};

export default SplashLoader;
