import { ContentPane } from "components/ui/contentPane";
import React from "react";
import "./privacy.scss";
import { NavLink } from "react-router-dom";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";

function PrivacyPage({ containerCss }) {
  return (
    <>
      <RcHelmet
        title="Abbatoir Pest Control"
        description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution"
        keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination"
      />
      <ContentPane
        isFullWidth={false}
        hasShadow={true}
        containerCss={`privacy-wrapper ${containerCss}`}
        contentPaddingHorizontal="3"
        contentPaddingVertical="3"
        contentCss="content-css"
        mainContentCss="px-2"
      >
        <h1>Privacy Policy</h1>
        <hr />
        <p>
          This Privacy Policy governs our collection, use, and disclosure of
          your Personal Information in connection with your use of our website
          and Personal Information we collect from you or third parties through
          any other means to provide our services or perform other business
          activities (collectively, “Services”). The purpose of this Privacy
          Policy is to explain what information we collect about you, how we use
          that information, if we disclose that information and, if so, how, to
          exercise any rights you may have with respect to your Personal
          Information, and the safeguards we have in place to reduce the risk of
          unauthorized access and use. This Privacy Policy is part of our Terms
          of Use for our Site. Please carefully read this Privacy Policy before
          using our Site or Services. By Accessing (as defined below) or using
          our Sites or Services you agree to our use of your information
          consistent with the Terms of Use and this Privacy Policy.
        </p>
        <hr />
        <h3 className="my-4">APMI Privacy Policy</h3>
        <p>
          At APMI, we respect your privacy and are committed to protecting it
          through our compliance with this policy.
        </p>
        <p>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit this website and our practices
          for collecting, using, maintaining, protecting and disclosing that
          information.
        </p>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text and other electronic messages between you and this
            Website.
          </li>
          <li>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this policy.
          </li>
        </ul>
        <br />
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>
            Us offline or through any other means, including on any other
            website operated by Company or
          </li>
          <li>
            Any third party, including through any application or content
            (including advertising) that may link to or be accessible from or on
            the Website.
          </li>
        </ul>
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time (see Changes
          to Our Privacy Policy). Your continued use of this Website after we
          make changes is deemed to be acceptance of those changes, so please
          check the policy periodically for updates.
        </p>
        <h3 className="my-4">
          Information We Collect About You and How We Collect It
        </h3>
        <p>
          We collect several types of information from and about users of our
          Website, including information:
        </p>
        <ul>
          <li>
            By which you may be personally identified, such as name, postal
            address, e-mail address or telephone number (“personal
            information”);
          </li>
          <li>
            That is about you but individually does not identify you; and/or
          </li>
          <li>
            About your internet connection, the equipment you use to access our
            Website and usage details.
          </li>
        </ul>
        <br />
        <p>We collect this information:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses and
            information collected through cookies.
          </li>
          <li>
            About your internet connection, the equipment you use to access our
            Website and usage details.
          </li>
        </ul>
        <h3 className="my-4">Information You Provide to Us</h3>
        <p>The information we collect on or through our Website may include:</p>
        <ul>
          <li>
            Information that you provide by filling in forms on our Website.
            This includes information provided at the time of registering to use
            our Website.
          </li>
          <li>
            Records and copies of your correspondence (including email
            addresses), if you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li>
            Details of transactions you carry out through our Website and of the
            fulfillment of your service request. You may be required to provide
            financial information before placing a service request through our
            Website.
          </li>
          <li>Your search queries on the Website.</li>
        </ul>
        <p>
          You also may provide information to be published or displayed
          (hereinafter, “posted”) on public areas of the Website (collectively,
          “User Contributions”). Your User Contributions are posted on and
          transmitted to others at your own risk. Although we limit access to
          certain pages/you may set certain privacy settings for such
          information by logging into your account profile], please be aware
          that no security measures are perfect or impenetrable. Additionally,
          we cannot control the actions of other users of the Website with whom
          you may choose to share your User Contributions. Therefore, we cannot
          and do not guarantee that your User Contributions will not be viewed
          by unauthorized persons.
        </p>
        <h3 className="my-4">How We Use Your Information</h3>
        <p>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </p>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products or services that you
            request from us.
          </li>
          <li>To provide you with information about our services</li>
          <li>
            To provide you with notices about your account/subscription,
            including expiration and renewal notices.
          </li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </li>
          <li>
            To allow you to participate in interactive features on our Website.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>To fulfill any purpose for which you provide it.</li>
          <li>For any other purpose with your consent.</li>
          <li>Accessing and Correcting Your Information</li>
        </ul>
        <p>
          You may send us an e-mail via our contact link to request access to,
          correct or delete any personal information that you have provided to
          us. We cannot delete your personal information except by also deleting
          your user account. We may not accommodate a request to change
          information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </p>
        <h3 className="my-4">Data Security</h3>
        <p>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration and disclosure
        </p>
        <p>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </p>
        <h3 className="my-4">Changes to Our Privacy Policy</h3>
        <p>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users’
          personal information, we will notify you by e-mail to the e-mail
          address specified in your account and/or through a notice on the
          Website home page. The date the privacy policy was last revised is
          identified at the top of the page. You are responsible for ensuring we
          have an up-to-date active and deliverable e-mail address for you, and
          for periodically visiting our Website and this privacy policy to check
          for any changes.
        </p>
        <h3 className="my-4">Cookie Policy</h3>
        <p>
          <NavLink to="/cookie-policy">Click here</NavLink>
        </p>
        <h3 className="my-4">Contact Information</h3>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact via our{" "}
          <NavLink to="/#contacts">Contact Us</NavLink> link.
        </p>
        <p className="fw-600 my-4">Thank you for visiting APMI website.</p>
      </ContentPane>
    </>
  );
}

export default PrivacyPage;
