import * as Yup from "yup";

export const serviceValidator = Yup.object().shape({
  serviceTypeId: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Service Type is required."),
  serviceId: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Service is required."),
});
