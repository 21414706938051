import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
//import { Divide as Hamburger } from "hamburger-react";
import { Spin as Hamburger } from "hamburger-react";
import "./rc-navbar.scss";
import { FixedNavOptions, MenuPlacementOptions } from "./navBarConstants";
import HashNavLink from "./hashNavLink/HashNavLink";

function RcNavBar({
  id,
  variant,
  menus,
  brandLink,
  brandLogo,
  brandLogoHeight,
  brandLogoWidth,
  brandText,
  offCanvasBrandLogo,
  offCanvasHeaderCss,
  offCanvasBrandLogoHeight,
  offCanvasBrandLogoWidth,
  brandLogoAlternateText,
  fixed,
  menuPlacement,
  isOffCanvasMenu,
  navBarRightPane,
  expand,
  offCanvasPlacement,
  brandContainerCss,
  burgerMenuColor,
  containerCss,
  offCanvasCustomComponent,
  navBarCss,
}) {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  // Handle Nav Menu open/close
  useEffect(() => {
    if (!isBurgerOpen) {
      setIsNavMenuOpen(isBurgerOpen);
      return;
    }

    setTimeout(() => {
      setIsNavMenuOpen(isBurgerOpen);
    }, 300);
  }, [isBurgerOpen]);

  const fixedOption =
    fixed === FixedNavOptions.TOP
      ? FixedNavOptions.TOP
      : fixed === FixedNavOptions.BOTTOM
      ? FixedNavOptions.BOTTOM
      : FixedNavOptions.TOP;

  const menuPlacementOption =
    menuPlacement === MenuPlacementOptions.START
      ? "me-auto"
      : menuPlacement === MenuPlacementOptions.END
      ? "ms-auto"
      : "ms-auto me-auto";

  const offCanvasLogo = !!offCanvasBrandLogo ? offCanvasBrandLogo : brandLogo;

  const handleNavLink = () => {
    if (isNavMenuOpen) {
      setIsBurgerOpen(false);
    }
  };

  const getBrand = () => {
    if (brandText) {
      return (
        <Navbar.Brand
          href={brandLink}
          className={`navbar-brand-text-container ${brandContainerCss}`}
        >
          {brandText}
        </Navbar.Brand>
      );
    }

    return (
      <>
        {/* Desktop */}
        <Navbar.Brand
          href={brandLink}
          target="_blank"
          className={`${brandContainerCss} d-none d-lg-flex`}
        >
          <img
            src={brandLogo}
            height={brandLogoHeight ? `${brandLogoHeight}px` : null}
            width={brandLogoWidth ? `${brandLogoWidth}px` : null}
            className="d-inline-block align-top navbar-brand-logo"
            alt={brandLogoAlternateText}
          />
        </Navbar.Brand>

        {/* Feature #1:  Lg and below  - FOR LEFT Aligned Brand Logo*/}
        <Navbar.Brand
          href={brandLink}
          target="_blank"
          className={`${brandContainerCss} d-flex d-lg-none flex-row`}
        >
          <img
            src={brandLogo}
            height={brandLogoHeight ? `${brandLogoHeight}px` : null}
            width={brandLogoWidth ? `${brandLogoWidth}px` : null}
            className="d-inline-block align-top navbar-brand-logo"
            alt={brandLogoAlternateText}
          />
        </Navbar.Brand>

        {/* Feature #2: Lg and below  - FOR CENTERED Brand Logo*/}
        {/* <div
          className={`d-flex d-lg-none flex-row justify-content-center w-100 logo lg-brand z-index-1`}
        >
          <Navbar.Brand
            href={brandLink}
            target="_blank"
            className={brandContainerCss}
          >
            <img
              src={brandLogo}
              height={brandLogoHeight ? `${brandLogoHeight}px` : null}
              width={brandLogoWidth ? `${brandLogoWidth}px` : null}
              className="d-inline-block align-top navbar-brand-logo"
              alt={brandLogoAlternateText}
            />
          </Navbar.Brand>
        </div> */}
      </>
    );
  };

  const getNavMenus = () => {
    return isOffCanvasMenu ? getOffCanvasMenus() : getToggleNavMenus();
  };

  const getToggleNavMenus = () => {
    return (
      <Navbar.Collapse id={id}>
        <Nav className={`my-2 my-lg-0 ${menuPlacementOption}`}>
          {getMenuItems()}
        </Nav>
        <Nav>{navBarRightPane({ showOffCanvas: setIsBurgerOpen })}</Nav>
      </Navbar.Collapse>
    );
  };

  const getOffCanvasMenus = () => {
    return (
      <Navbar.Offcanvas
        id={id}
        aria-labelledby={`${id}OffCanvasLabel`}
        placement={offCanvasPlacement}
        className={`offcanvas-${variant}`}
      >
        <Offcanvas.Header className={offCanvasHeaderCss} closeButton>
          <Offcanvas.Title id={`${id}OffCanvasLabel`}>
            {brandText ? (
              <div>{brandText}</div>
            ) : (
              <img
                src={offCanvasLogo}
                height={
                  offCanvasBrandLogoHeight
                    ? `${offCanvasBrandLogoHeight}px`
                    : null
                }
                width={
                  offCanvasBrandLogoWidth
                    ? `${offCanvasBrandLogoWidth}px`
                    : null
                }
                className="d-inline-block align-top"
                alt={brandLogoAlternateText}
              />
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav
            className={`d-none d-lg-flex my-2 my-lg-0 ${menuPlacementOption}`}
          >
            {getMenuItems()}
          </Nav>
          {!isNavMenuOpen && (
            <Nav>{navBarRightPane({ showOffCanvas: setIsBurgerOpen })}</Nav>
          )}
          {isNavMenuOpen && (
            <>
              <Nav
                className={`d-flex d-lg-none my-2 my-lg-0 ${menuPlacementOption}`}
              >
                {getMenuItems()}
              </Nav>
              {navBarRightPane({ showOffCanvas: setIsBurgerOpen })}
              <div className={`d-flex d-lg-none`}>
                {offCanvasCustomComponent}
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    );
  };

  const getMenuItems = () => {
    // #1st Level
    return menus.map((root, i) => {
      if (root?.visible === false) return null;

      // #2nd Level Parent has children?
      if (root?.type === "parent") {
        return (
          <NavDropdown title={root.label} id={`${id}Dropdown`} key={i}>
            {root?.subMenus.map((child, childIndex) => {
              if (child?.type === "divider")
                return <NavDropdown.Divider key={childIndex} />;

              return (
                <NavDropdown.Item
                  key={childIndex}
                  to={child?.url}
                  as={HashNavLink}
                  onClick={handleNavLink}
                  //as={NavLink}
                >
                  {child.label}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        );
      }

      return (
        // <Nav.Link key={i} to={root.url} as={NavLink} end>
        <Nav.Link
          key={i}
          to={root.url}
          as={HashNavLink}
          onClick={handleNavLink}
        >
          {root.label}
        </Nav.Link>
      );
    });
  };

  const getRightAlignedToggle = () => {
    // Feature #1: FOR CENTERED brand enable this !!!
    // <div className={`d-flex d-${expand}-none`}>
    //   <Hamburger
    // toggled={isBurgerOpen}
    // toggle={setIsBurgerOpen}
    //     className={`ms-auto z-index-5 ${
    //       !isOpen ? "navbar-toggler collapsed" : "navbar-toggler"
    //     }`}
    //     color={burgerMenuColor}
    //     aria-controls="Hamburger menu"
    //   />
    // </div>;

    // Feature #2: FOR LEFT aligned Toggle enabled this
    return (
      // <Navbar.Toggle aria-controls="navbarScroll">
      <div className={`d-flex d-${expand}-none`}>
        <Hamburger
          toggled={isBurgerOpen}
          toggle={setIsBurgerOpen}
          className={`${
            !isBurgerOpen ? "navbar-toggler collapsed" : "navbar-toggler"
          }`}
          color={burgerMenuColor}
          rounded={true}
          size={20}
          aria-controls="Hamburger menu"
        />
      </div>
      // </Navbar.Toggle>
    );
  };

  const handleToggle = (e) => {
    setIsBurgerOpen(false);
  };

  const handleSelect = () => {
    setIsBurgerOpen(false);
  };

  return (
    <Navbar
      //bg={bg}
      variant={variant}
      fixed={fixedOption}
      expand={expand}
      className={`rca-nav-bar ${navBarCss}`}
      expanded={isNavMenuOpen}
      collapseOnSelect
      onToggle={handleToggle}
      onSelect={handleSelect}
    >
      <Container fluid={false} className="nav-container rc-navbar">
        {getBrand()}
        {getRightAlignedToggle()}
        {getNavMenus()}
      </Container>
    </Navbar>
  );
}

RcNavBar.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.string,
  menus: PropTypes.array,
  brandLink: PropTypes.string,
  brandLogo: PropTypes.string,
  brandLogoHeight: PropTypes.number,
  brandLogoWidth: PropTypes.number,
  brandText: PropTypes.string,
  brandLogoAlternateText: PropTypes.string,
  offCanvasBrandLogo: PropTypes.string,
  offCanvasPlacement: PropTypes.string,
  offCanvasHeaderColor: PropTypes.string,
  offCanvasBrandLogoHeight: PropTypes.number,
  offCanvasBrandLogoWidth: PropTypes.number,
  isOffCanvasMenu: PropTypes.bool,
  navBarRightPane: PropTypes.func,
  fixed: PropTypes.string,
  menuPlacement: PropTypes.string,
  expand: PropTypes.string,
  brandContainerCss: PropTypes.string,
  burgerMenuColor: PropTypes.string,
  containerCss: PropTypes.string,
  contentStyle: PropTypes.object,
  offCanvasCustomComponent: PropTypes.object,
  navBarCss: PropTypes.string,
};

RcNavBar.defaultProps = {
  id: "RcnetAppNavBar",
  variant: "dark",
  menus: [],
  fixed: "top",
  menuPlacement: "end",
  brandLogoHeight: 40,
  brandLogoAlternateText: "RcnetApp Template",
  expand: "lg",
  offCanvasPlacement: "end",
  offCanvasHeaderCss: "bg-white",
  offCanvasBrandLogoHeight: 50,
  burgerMenuColor: "#4FD1C5",
  navBarRightPane: () => {},
};

export default RcNavBar;
