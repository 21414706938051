import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import "./mission-vision.scss";
import { Col, Image, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { RcButton } from "components/ui/rcButton";
import SectionTitle from "components/ui/pageSection/SectionTitle";

function MissionVision({ containerCss }) {
  return (
    <ContentPane
      isFullWidth={true}
      hasShadow={false}
      containerCss="mission-vision-wrapper"
      contentCss="content-box"
      contentPaddingHorizontal="3"
      contentPaddingVertical="3"
      mainContentCss="section-content-wrapper px-5x py-5"
    >
      <SectionTitle
        title="Mission and Vision"
        subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore"
      />
      <motion.div
        initial={{ opacity: 0, y: "18vh" }}
        transition={{ ease: "easeInOut", duration: 0.8 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="row section-content"
      >
        <Col md={6} className="">
          <div className="mission-container d-flex flex-column align-items-center">
            <Image
              src="/images/mission-vision/mission.jpg"
              className="image mb-4"
              fluid
              rounded
            />
            <div className="description-container d-flex flex-column align-items-center rounded shadow">
              <h3 className="mb-3">Mission</h3>
              <section className="bullet-list">
                <div className="d-flex flex-row">
                  <div>
                    <i class="fas fa-check me-3"></i>
                  </div>
                  <div>
                    To be the leading pest control provider to both commercial
                    and residential clients.
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <i class="fas fa-check me-3"></i>
                  </div>
                  <div>
                    To be the primary catalysts in providing the best pest
                    control services for our customers, driven by desire to help
                    them protect their properties and lives through a highly
                    motivated team of competent and empowered professionals,
                    guided by in-depth knowledge on pest control with advanced
                    technology and equipment.
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <i class="fas fa-check me-3"></i>
                  </div>
                  <div>
                    To maintain the highest ethical standards, sense of
                    responsibility and respect.
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Col>
        <Col md={6} className="">
          <div className="vision-container d-flex flex-column align-items-center">
            <Image
              src="/images/mission-vision/vision3.webp"
              className="image2 mb-4"
              fluid
              rounded
            />
            <div className="description-container2 d-flex flex-column align-items-center rounded shadow">
              <h3 className="mb-3">Vision</h3>
              <p>
                APMI is committed to creating a better world and wellness in
                protecting the lives and properties of many through the quality
                service we provide.
              </p>
            </div>
          </div>
        </Col>
      </motion.div>
    </ContentPane>
  );
}

MissionVision.propTypes = {
  containerCss: PropTypes.string,
};

MissionVision.defaultProps = {};

export default MissionVision;
