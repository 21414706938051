import { Image } from "react-bootstrap";
import "./page-500.scss";
import { RcButton } from "components/ui/rcButton";
import { useNavigate } from "react-router-dom";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";

function Page500() {
  const navigate = useNavigate();

  const handleGoBack = () => navigate("/");

  return (
    <section className="page-500-wrapper text-center">
      <RcHelmet
        title="Abbatoir Pest Control"
        description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution"
        keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination"
      />
      <h1 className="rca-heading1">Server Error</h1>
      <h2 className="rca-heading5">Well, this is embarassing...</h2>
      <Image src="/images/misc/page-500.webp" />
      {/* <a href="http://www.freepik.com">Designed by stories / Freepik</a> */}
      {/* <h5>It's not you, it's me.</h5> */}
      <h5>
        Sorry, this is not working properly. We now know about this mistake and
        are working to fix it.
      </h5>
      <div>
        <RcButton
          variant="pill-primary"
          size="lg"
          containerCss="my-4"
          onClick={handleGoBack}
        >
          Go Back Home
        </RcButton>
      </div>
    </section>
  );
}

export default Page500;
