import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "components/ui/formikFields/formInput/FormInput";
import { RcButton } from "components/ui/rcButton";
import { FormPhoneInput, FormWatch } from "components/ui/formikFields";
import CheckboxField from "components/ui/formFields/checkboxField/CheckboxField";
import FormCheckboxGroup from "components/ui/formikFields/formCheckboxGroup/FormCheckboxGroup";
import FormCheckbox from "components/ui/formikFields/formCheckbox/FormCheckbox";
import Select from "react-select";
import FormSelectField from "components/ui/formikFields/formSelect/FormSelectField";
import { Col, Row } from "react-bootstrap";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";
import * as SystemSlice from "store/system/systemSlice";
import { useDispatch } from "react-redux";
import FormDatePicker from "components/ui/formikFields/formDatePicker/FormDatePicker";
import FormTimePicker from "components/ui/formikFields/formTimePicker/FormTimePicker";

const options = [
  {
    value: "1",
    label: "Mango",
  },
  {
    value: "2",
    label: "Apple",
    disabled: true,
  },
  {
    value: "3",
    label: "Guava",
  },
  {
    value: "4",
    label: "Papaya",
  },
];

const settingsOptions = [
  {
    value: "1",
    label: "Enable Logging",
  },
  {
    value: "2",
    label: "Track Data Usage",
    disabled: true,
  },
  {
    value: "3",
    label: "Delete Cookie History",
  },
  {
    value: "4",
    label: "Enable ChatGPT 4",
  },
];

const selectData = [
  {
    value: "1",
    label: "New",
  },
  { value: "2", label: "Existing" },
  { value: "3", label: "Renewal" },
];

const selectHeroData = [
  {
    value: "1",
    label: "Faceless Void",
  },
  { value: "2", label: "Troll Warlord" },
  { value: "3", label: "Terror Blade" },
];

const jobOptions = [
  {
    value: "1",
    label: "Paladin",
  },
  {
    value: "2",
    label: "Wizard",
  },
  {
    value: "3",
    label: "Mage",
  },
  {
    value: "4",
    label: "Assasin",
  },
];

function FormikDemo({ validator, onFormSubmit }) {
  const dispatch = useDispatch();

  const [blocking, setBlocking] = useState(false);

  const handleBlockUI = () => setBlocking(!blocking);

  const handleNotification = () => {
    // Normal
    dispatch(SystemSlice.showNotification("Hello!"));

    // Custom message
    dispatch(
      SystemSlice.showNotification({
        title: "Test",
        message: "Success message!",
        type: "success",
        detailsUrl: "/system",
      })
    );

    dispatch(
      SystemSlice.showNotification({
        title: "Test",
        message: "Warn message!",
        type: "warn",
        detailsUrl: "/system",
      })
    );

    // Error
    dispatch(
      SystemSlice.showNotificationError({
        message: "Error message",
        error: new Error("Hadouken"),
      })
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        fname: "",
        lname: "",
        email: "",
        optionFruit: [],
        settingsOptions: [
          {
            value: "1",
            label: "Enable Logging",
          },
          {
            value: "4",
            label: "Enable ChatGPT 4",
          },
        ],
        netFlix: null,
        switchMe: false,
        customerType: [
          {
            value: "1",
            label: "New",
          },
          { value: "2", label: "Existing" },
        ],
        hero: [],
        jobType: [],
        startDate: "",
        endDate: "",
        myTime: "",
        phoneNumber: "",
      }}
      validationSchema={validator}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, isSubmitting, ...rest }) => (
        <>
          <RcBlockUi blocking={blocking}>
            <h3>Formik</h3>
            <div className="row">
              <FormInput
                name="fname"
                label="First name"
                labelBold={true}
                showRequiredLabel={false}
                xs={12}
                md={6}
              />
              <FormInput
                name="lname"
                label="Last name"
                labelBold={true}
                showRequiredLabel={true}
                xs={12}
                md={6}
              />
            </div>
            <div className="row">
              <div className="col">
                <FormInput
                  name="email"
                  label="Email"
                  showLabel={false}
                  placeholder="youremail@example.com"
                  labelBold={true}
                  showRequiredLabel={true}
                />
              </div>
            </div>
            <CheckboxField
              title="Under this Agreement the Vendor grants to the Licensee a non-exclusive and non-transferable licence (the “Licence”) to use [Insert name of software] (the “Software”). 2. “Software” includes the executable computer programs and any related printed, electronic and online documentation and any other files that may accompany the product."
              name="checkboxAgreement"
              label="[I accept the agreement](https://rcnetlabs.com/services)"
              renderAsMarkdown={true}
            />

            <FormCheckbox
              type="switch"
              name="switchMe"
              label="Enable theming"
            />

            <FormCheckbox
              name="netFlix"
              label="Watch Netflix"
              helpText="Receive movie clips from Netflix."
            />

            <FormCheckboxGroup
              label="My Favorite Fruits"
              name="optionFruit"
              options={options}
              inline={true}
              plaintext
            />

            <FormCheckboxGroup
              type="switch"
              label="Application Settings"
              name="settingsOptions"
              options={settingsOptions}
              helpText="Toggle settings based on your preferences."
              plaintext
            />

            <FormSelectField
              name="customerType"
              label="Customer Type"
              options={selectData}
              closeMenuOnSelect={true}
              allowSelectAll
              isMulti
              plaintext
              plaintextInline
            />

            <Row>
              <FormSelectField
                name="customerType"
                label="Customer Type"
                options={selectData}
                closeMenuOnSelect={true}
                allowSelectAll
                isMulti
                xs={12}
                md={8}
              />
              <FormSelectField
                name="hero"
                label="Select a hero"
                options={selectHeroData}
                closeMenuOnSelect={true}
                allowSelectAll
                isMulti
                xs={12}
                md={4}
              />
            </Row>

            <FormCheckboxGroup
              type="radio"
              label="Job Type"
              name="jobType"
              options={jobOptions}
              helpText="Select character role."
              inline
            />

            <Row>
              <Col>
                <FormDatePicker
                  name="startDate"
                  label="Start Date"
                  showRequiredLabel={true}
                />
              </Col>
              <Col>
                <FormDatePicker
                  name="endDate"
                  label="End Date"
                  dateFormat="DD/MM/YYYY"
                  plaintext
                  showRequiredLabel={true}
                />
              </Col>
              <Col>
                <FormDatePicker
                  name="month"
                  label="Select Month"
                  plaintext
                  isMonthPicker={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormTimePicker name="myTime" label="My Time" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormPhoneInput
                  name="phoneNumber"
                  label="Phone"
                  mask="mobile"
                />
              </Col>
            </Row>
          </RcBlockUi>
          <FormWatch />
          <RcButton
            variant="outline-dark-pill-primary"
            containerCss="me-2"
            onClick={handleSubmit}
          >
            Submit
          </RcButton>
          <RcButton
            variant="outline-dark-pill-secondary"
            containerCss="me-2"
            onClick={() => {}}
          >
            Reset
          </RcButton>
          <RcButton
            variant="primary"
            containerCss="me-2"
            onClick={handleBlockUI}
          >
            Block UI
          </RcButton>
          <RcButton
            variant="primary"
            containerCss="me-2"
            onClick={handleNotification}
          >
            Notification
          </RcButton>
        </>
      )}
    </Formik>
  );
}

FormikDemo.propTypes = {
  validator: PropTypes.object,
  containerCss: PropTypes.string,
};

FormikDemo.defaultProps = {};

export default FormikDemo;
