import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, Table } from "react-bootstrap";
import "./quote-services.scss";
import { Outlet, useLocation } from "react-router-dom";
import AddService from "./AddService";
import ServicesGrid from "./ServicesGrid";
import * as QuotationSlice from "store/modules/quotation-slice";
import { useDispatch, useSelector } from "react-redux";

function QuotationServices({ onDeleteClick, containerCss }) {
  const location = useLocation();
  const { quotations } = useSelector(QuotationSlice.selectQuotation);

  return (
    <section className={`quote-services-wrapper ${containerCss}`}>
      <div className="section-header">
        <h5 className="p-2 ps-3">Add/Remove Services</h5>
      </div>
      <Row className="gx-0">
        <Col md="4">
          <div className="p-2 content service-form position-relative">
            {location.pathname === "/quotation" && <AddService />}
            <Outlet />
          </div>
        </Col>
        <Col>
          <ServicesGrid
            data={quotations}
            containerCss="p-2 content"
            onDeleteClick={onDeleteClick}
          />
        </Col>
      </Row>
    </section>
  );
}

QuotationServices.propTypes = {
  onDeleteClick: PropTypes.func,
  containerCss: PropTypes.string,
};

QuotationServices.defaultProps = {};

export default QuotationServices;
