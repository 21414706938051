import React from "react";
import PropTypes from "prop-types";
import FormInputDemo from "components/features/demo/FormInputDemo";
import FormikDemo from "components/features/demo/FormikDemo";
import { formDemoValidator } from "./validators/form-validator";
import axios from "axios";

function InputDemoPage({ containerCss }) {
  const handleUpload = (files, resolve, reject) => {
    console.log(files);
    debugger;
    let formData = new FormData();

    files.forEach((file) => {
      formData.append("myFiles", file.data, file.name);
    });

    axios({
      method: "post",
      url: "http://localhost:55488/api/v1/todoItem/upload",
      data: formData,
    })
      .then((resp) => {
        console.log(resp);
        resolve("Ayos na upload na!");
      })
      .catch((ex) => {
        console.log(ex);
        reject(ex);
      });
  };

  return (
    <section className={containerCss}>
      <h2 className="mb-5">Input</h2>
      <FormInputDemo onUpload={handleUpload} />
      <FormikDemo validator={formDemoValidator} />
    </section>
  );
}

InputDemoPage.propTypes = {
  containerCss: PropTypes.string,
};

InputDemoPage.defaultProps = {};

export default InputDemoPage;
