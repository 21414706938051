import { useDispatch } from "react-redux";
import * as SystemSlice from "store/system/systemSlice";

const useSystem = () => {
  const dispatch = useDispatch();

  const notifyBase = (title, message, type, detailsUrl = null) => {
    dispatch(
      SystemSlice.showNotification({
        title,
        message,
        type,
        detailsUrl,
      })
    );
  };

  const notify = (message) => {
    dispatch(SystemSlice.showNotification(message));
  };

  const notifySuccess = (title, message, detailsUrl = null) => {
    notifyBase(title, message, "success", detailsUrl);
  };

  const notifyWarning = (title, message, detailsUrl = null) => {
    notifyBase(title, message, "warn", detailsUrl);
  };

  const notifyError = (message, err) => {
    dispatch(
      SystemSlice.showNotificationError({
        message,
        error: err,
      })
    );
  };

  return { notify, notifySuccess, notifyWarning, notifyError };
};

export default useSystem;
