import { ContentPane } from "components/ui/contentPane";
import React from "react";
import "./cookie.scss";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";
import { NavLink } from "react-router-dom";

function CookiePolicyPage({ containerCss }) {
  return (
    <>
      <RcHelmet
        title="Abbatoir Pest Control"
        description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution"
        keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination"
      />
      <ContentPane
        isFullWidth={false}
        hasShadow={true}
        containerCss={`cookie-wrapper ${containerCss}`}
        contentPaddingHorizontal="3"
        contentPaddingVertical="3"
        contentCss="content-css"
        mainContentCss="px-2"
      >
        <h1>Cookie Policy</h1>
        <hr />
        <p>
          This website uses cookies to improve your experience while you
          navigate through the website. Out of these cookies, the cookies that
          are categorized as necessary are stored on your browser as they are
          essential for the working of basic functionalities of the website. We
          also use third-party cookies that help us analyze and understand how
          you use this website. These cookies will be stored in your browser
          only with your consent. You also have the option to opt-out of these
          cookies. But opting out of some of these cookies may have an effect on
          your browsing experience.
        </p>
        <p>
          Necessary cookies are absolutely essential for the website to function
          properly. This category only includes cookies that ensures basic
          functionalities and security features of the website. These cookies do
          not store any personal information.
        </p>
        <p>
          Any cookies that may not be particularly necessary for the website to
          function and is used specifically to collect user personal data via
          analytics, ads, other embedded contents are termed as non-necessary
          cookies. It is mandatory to procure user consent prior to running
          these cookies on your website.
        </p>
        <p>APMI may use the following types of cookies:</p>
        <p>
          <p className="fw-600">Essential Cookies</p>
          These cookies are necessary for our website to function properly and
          cannot be switched off in our systems. They are usually only set in
          response to actions made by you which amount to a request for
          services, such as setting your privacy preferences, logging in or
          filling in forms.
        </p>
        <p>
          <p className="fw-600">Analytics Cookies</p>
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our site. They help us to know
          which pages are the most and least popular and see how visitors move
          around the site
        </p>

        <p>
          <p className="fw-600">Advertising Cookies</p>
          These cookies may be set through our site by our advertising partners.
          They may be used by those companies to build a profile of your
          interests and show you relevant adverts on other sites. They do not
          store directly personal information but are based on uniquely
          identifying your browser and internet device. If you do not allow
          these cookies, you will experience less targeted advertising.
        </p>
        <p>
          You can set your browser to block or alert you about these cookies,
          but some parts of the site will not then work. These cookies do not
          store any personally identifiable information.
        </p>
        <h3 className="my-4">Privacy Policy</h3>
        <p>
          <NavLink to="/privacy-policy">Click here</NavLink>
        </p>
        <p>
          If you have any questions about our use of cookies or other
          technologies, please email us at{" "}
          <a href="mailto:abbatoirpestmgntinc@yahoo.com">
            abbatoirpestmgntinc@yahoo.com
          </a>
        </p>
        <p className="fw-600 my-4">Thank you for visiting APMI website.</p>
      </ContentPane>
    </>
  );
}

export default CookiePolicyPage;
