import React, { useEffect, useState, useCallback, useMemo } from "react";
import { PropTypes } from "prop-types";
import { useDropzone } from "react-dropzone";
import { Card, Col, Row } from "react-bootstrap";
import { noop } from "lodash";
import "./rc-file-upload.scss";
import styled from "styled-components";
import {
  fileListToBase64,
  toKilobyte,
  getColor,
  limitFileName,
  fileListToArrayBuffer,
  getForFileUploading,
} from "./utils/helper";
//import ImagePreviewFx from "./../image-preview-fx/image-preview-fx";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";
import IconProvider from "./utils/IconProvider";
import ImageListing from "./imageListing/ImageListing";
import FileListing from "./imageListing/FileListing";
import FileRejections from "./rejections/FileRejections";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "./styles/style-object";

function RcFileUpload({
  text,
  dropText,
  minSize,
  maxSize,
  maxFiles,
  initialFiles,
  mode,
  fileTypes,
  isReadonly,
  cloudStoragePath,
  uploadLabel,
  onChange,
  onUpload,
}) {
  const [files, setFiles] = useState([]);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isDropping, toggleDrop] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Reject files exceeding the maxFiles limit
      toggleDrop(true);

      let finalAcceptedFiles = [];

      let filesCount = files.length + acceptedFiles.length;

      if (filesCount > maxFiles) {
        let itemsUpperLimit = maxFiles - files.length;
        finalAcceptedFiles = acceptedFiles.slice(0, itemsUpperLimit);
      } else {
        finalAcceptedFiles = acceptedFiles;
      }

      let filesWithAdditionalProps = finalAcceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      // Convert to base64 each file
      //const arrayOfBase64Files = await fileListToBase64(filesWithAdditionalProps);
      const arrayOfBuffer = await fileListToArrayBuffer(
        filesWithAdditionalProps
      );

      // Complete set of files
      files.push(...arrayOfBuffer);
      //files.push(...arrayOfBase64Files);
      //files.push(...filesWithAdditionalProps);

      setFiles(files);

      // Determine which files to upload
      let forFileUploading = getForFileUploading(arrayOfBuffer);
      debugger;

      // Upload to server if handled by consumer
      let promiseCb = new Promise((resolve, reject) => {
        onUpload(forFileUploading, resolve, reject);
      });

      promiseCb
        .then((resp) => {
          alert(resp);
          onChange([...files]);
          toggleDrop(false);
        })
        .catch((ex) => {
          alert(ex);
        });
    },
    [files, maxFiles, onChange, onUpload]
  );

  const getFileTypesAccepted = () => {
    if (mode === "Image")
      return { "image/*": [".png", ".gif", ".jpeg", ".jpg"] };

    if (mode === "File" && fileTypes.length === 0) {
      return {
        "image/*": [".pdf", ".jpg", ".jpeg", ".png", ".gif", ".bmp"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      };
    } else {
      return fileTypes;
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    //rejectedFiles,
    acceptedFiles, //
  } = useDropzone({
    accept: getFileTypesAccepted(),
    minSize: minSize,
    maxSize: maxSize,
    //multiple: true,
    onDrop,
    disabled: isReadonly,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const openPreview = (file) => {
    setPreviewUrl(file.preview);
    setPreviewOpen(true);
  };

  const removeFile = useCallback(
    (file) => () => {
      if (isReadonly) return;

      URL.revokeObjectURL(file.preview);
      let filesUpdated = files.filter((o) => o !== file);
      setFiles(filesUpdated);

      onChange([...filesUpdated]);
    },
    [files, isReadonly, onChange]
  );

  const closePreview = (e) => {
    setPreviewOpen(false);
  };

  const downloadFile = useCallback(
    (file) => () => {
      const { name, type } = file;

      if (type === "new") return false;

      var a = document.createElement("a");
      a.href = `${cloudStoragePath}/${name}`;
      a.setAttribute("download", "download");
      var b = document.createEvent("MouseEvents");
      b.initEvent("click", false, true);
      a.dispatchEvent(b);

      return false;
    },
    [cloudStoragePath]
  );

  const DragMessage = ({ isDragActive, isDragReject, rejected }) => {
    return (
      <>
        <RcBlockUi blocking={isDropping}>
          {!isDragActive && !rejected && (
            <div
              className="text-center drag-msg"
              style={{ userSelect: "none", pointerEvents: "none" }}
            >
              <div>{uploadLabel}</div>
              <div>
                {text} Maximum of {maxFiles} file{maxFiles > 1 ? "s" : ""}.
              </div>
              {files.length > 0 ? (
                <div className="small text-primary">
                  <strong>{files.length}</strong> file(s) attached.
                </div>
              ) : null}
            </div>
          )}
          {isDragActive && !rejected && !isDragReject && (
            <p
              className="mt-3 drag-msg fg-blue"
              style={{ userSelect: "none", pointerEvents: "none" }}
            >
              {dropText}
            </p>
          )}
          {(rejected || isDragReject) && (
            <p
              className="mt-3 drag-msg fg-red"
              style={{ userSelect: "none", pointerEvents: "none" }}
            >
              Unable to upload file(s)
            </p>
          )}
        </RcBlockUi>
      </>
    );
  };

  const reloadInitialFiles = () => {
    if (!initialFiles || initialFiles.length <= 0) return;

    setFiles(
      initialFiles.map((o) =>
        Object.assign(
          {},
          {
            type: "init",
            name: o.name,
            size: o.size,
            preview: getPreviewData(o),
          }
        )
      )
    );
  };

  const getPreviewData = (file) => {
    if (file.data.includes("data:")) return file.data;
    return `${cloudStoragePath}/${file.name}`;
  };

  const isImageMode = () => mode === "Image";

  // Component did mount
  useEffect(() => {
    reloadInitialFiles();
  }, []);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Col className="rca-fileUploader pe-0 ps-0 mb-3">
      {/* <ImagePreviewFx
          imageLargeUrl={previewUrl}
          isOpen={isPreviewOpen}
          closePreview={closePreview}
          showRotate={true}
          alternateText={"Image Preview"}
        /> */}
      <div
        {...getRootProps({ style })}
        validForReject={fileRejections?.length > 0}
        className="mb-2x"
      >
        <input {...getInputProps()} />
        <DragMessage
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          rejected={fileRejections?.length > 0}
        />
        <FileRejections data={fileRejections} />
      </div>
      {isImageMode() ? (
        <aside className="thumbsContainer">
          <ImageListing
            files={files}
            isReadonly={isReadonly}
            onRemoveFile={removeFile}
            onOpenPreview={openPreview}
          />
        </aside>
      ) : (
        <Row className="thumbsContainer">
          <FileListing files={files} onRemoveFile={removeFile} />
        </Row>
      )}
    </Col>
  );
}

RcFileUpload.propTypes = {
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  mode: PropTypes.string, //Note: Mode can be Image or File, each mode has different layout and behavior
  fileTypes: PropTypes.array,
  uploadLabel: PropTypes.string,
  dropText: PropTypes.string,
  initialFiles: PropTypes.array,
  isReadonly: PropTypes.bool,
  cloudStoragePath: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
};

RcFileUpload.defaultProps = {
  minSize: 0,
  maxSize: 102400, // 100kb default
  maxFiles: 5,
  mode: "File",
  fileTypes: [],
  uploadLabel: "Drag 'n' drop some files here, or click to select files",
  dropText: "Drop here!",
  initialFiles: [],
  cloudStoragePath: "",
  isReadonly: false,
  onChange: noop,
  onUpload: noop,
};

export default RcFileUpload;
