function hasFuncHandlerEnabled(code) {
  return ["AGBDC", "MBIT"].includes(code);
}

export function getProductServiceData(services) {
  const result = services.reduce((acc, curr) => {
    const serviceItems = curr.productServices.map((o) => {
      return {
        id: o.id,
        title: o.serviceName,
        description: o.description,
        buttonText: "Ask a Quotation",
        tag: o.serviceTag,
        code: o.entityCode,
        hasFuncHandler: hasFuncHandlerEnabled(o.entityCode),
      };
    });

    const serviceData = {
      id: curr.id,
      title: curr.serviceTypeName,
      description: curr.description,
      items: [...serviceItems],
    };

    acc.push(serviceData);

    return acc;
  }, []);

  return result;
}

export function getProductServiceQuoteData(services) {
  const result = services.reduce((acc, curr) => {
    const serviceItems = curr.productServices.map((o) => {
      return {
        value: o.id,
        label: o.serviceName,
        description: o.description,
      };
    });

    const serviceData = {
      value: curr.id,
      label: curr.serviceTypeName,
      subCategories: [...serviceItems],
    };

    acc.push(serviceData);

    return acc;
  }, []);

  return result;
}
