import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import { ourClientsData } from "data/ourClientsData";
import "./our-clients.scss";
import SlickCarousel from "components/ui/slickCarousel/SlickCarousel";

function OurClients() {
  return (
    <section className="our-clients-wrapper">
      <SectionTitle
        title="Our Clients"
        subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore"
      />
      {/* <OurClientsRotator data={ourClientsData} containerCss="section-content" /> */}
      <SlickCarousel
        data={ourClientsData}
        containerCss="section-content"
        sliderCss="slider"
      />
    </section>
  );
}

OurClients.propTypes = {};

OurClients.defaultProps = {};

export default OurClients;
