import { Navigate, Route } from "react-router-dom";
import LoginView from "pages/auth/login/loginView";

const authRoutes = () => {
  return (
    <>
      <Route index element={<LoginView />} />
      <Route path="login" element={<LoginView />} />
      <Route path="*" element={<Navigate to="pages/error-404" />} />
    </>
  );
};

export default authRoutes;
