import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ContentPane } from "components/ui/contentPane";
import "./quotation.scss";
import QuotationForm from "components/containers/quotation/QuoteForm";
import * as QuotationSlice from "store/modules/quotation-slice";
import { useNavigate } from "react-router-dom";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";

function QuotationPage({ containerCss }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector(QuotationSlice.selectQuotation);

  const handleFormSubmit = (e) => {
    dispatch(QuotationSlice.submitQuotation(e))
      .unwrap()
      .then(() => {
        navigate("/request-quotation-completed");
      });
  };

  return (
    <RcBlockUi blocking={loading}>
      <RcHelmet
        title="Abbatoir Pest Control"
        description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution"
        keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination"
      />
      <ContentPane
        id="quotation"
        isFullWidth={false}
        hasShadow={true}
        containerCss={`quotation-wrapper ${containerCss}`}
        contentPaddingHorizontal="3"
        contentPaddingVertical="3"
        contentCss="content-css"
        mainContentCss="px-2"
      >
        <h1>Quotation Request</h1>
        <hr />

        <QuotationForm onFormSubmit={handleFormSubmit} />
      </ContentPane>
    </RcBlockUi>
  );
}

QuotationPage.propTypes = {
  containerCss: PropTypes.string,
};

QuotationPage.defaultProps = {};

export default QuotationPage;
