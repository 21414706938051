import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import "./social.scss";

function Social({ containerCss }) {
  return (
    <motion.div
      initial={{ opacity: 0, x: "-20vh" }}
      transition={{ ease: "easeOut", duration: 0.8 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      className={`social-wrapper d-flex flex-row ${containerCss}`}
    >
      <a
        href="https://www.facebook.com/Abbatoirpmi"
        alt="Facebook page"
        target="_blank"
        rel="noreferrer"
        className="fb"
      >
        <i class="fa-brands fa-facebook fa-5xx p-4"></i>
      </a>
      <a
        href="https://instagram.com"
        alt="Instagram page"
        target="_blank"
        rel="noreferrer"
        className="ig"
      >
        <i class="fab fa-instagram fa-5xx p-4"></i>
      </a>
      {/* <a
        href="https://facebook.com"
        alt="Messenger"
        target="_blank"
        rel="noreferrer"
        className="msgr"
      >
        <i class="fab fa-facebook-messenger fa-5xx p-4"></i>
      </a> */}
    </motion.div>
  );
}

Social.propTypes = {
  containerCss: PropTypes.string,
};

Social.defaultProps = {};

export default Social;
