import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import AddServiceInput from "./AddServiceInput";
import { serviceValidator } from "../validators/serviceValidator";
import { FormWatch } from "components/ui/formikFields";
import { useNavigate } from "react-router-dom";
import * as QuotationSlice from "store/modules/quotation-slice";

function AddServiceForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = ({ serviceTypeId, serviceId }) => {
    dispatch(
      QuotationSlice.addService({
        serviceTypeId: serviceTypeId.value,
        serviceId: serviceId.value,
        serviceType: serviceTypeId.label,
        serviceName: serviceId.label,
      })
    );

    navigate(-1);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          serviceTypeId: null,
          serviceId: null,
        }}
        validationSchema={serviceValidator}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, isSubmitting, ...rest }) => (
          <AddServiceInput {...rest} onAdd={handleSubmit} />
        )}
      </Formik>
    </>
  );
}

AddServiceForm.propTypes = {};

AddServiceForm.defaultProps = {};

export default AddServiceForm;
