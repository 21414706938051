import React, { memo } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import "./mark-down.scss";

const MarkDown = ({ text, containerCss }) => {
  return (
    <ReactMarkdown
      className={`markdown ${containerCss}`}
      children={text}
      skipHtml={true}
      linkTarget="_blank"
    />
  );
};

MarkDown.propTypes = {
  text: PropTypes.string,
  containerCss: PropTypes.string,
};

MarkDown.defaultProps = {
  containerCss: "mb-2",
};

export default memo(MarkDown);
