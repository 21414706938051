import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isObject, uniqueId } from "lodash";

const initialState = {
  notifications: [],
  position: "top-end",
};

// ---------------
// Action Thunks
// ---------------
//
// Shape: {message: "", error: <object>}
//
export const showNotificationError = createAsyncThunk(
  "system/showNotificationError",
  async (data, thunkAPI) => {
    try {
      //Todo: perform logging here (sentry)
      if (!isObject(data)) return data;

      const { error } = data;

      // Handle HTTP 400 error
      if (error?.response?.status === 400) {
        console.log(error?.response?.data?.Errors[0]);

        return {
          message: data?.message,
          errorMessage: error?.response?.data?.Errors[0], // Return first validation error
          detailsUrl: data?.detailsUrl,
        };
      }

      if (error?.response?.status === 409) {
        console.log(error?.response?.data?.Message);

        return {
          message: data?.message,
          errorMessage: error?.response?.data?.Message,
          detailsUrl: data?.detailsUrl,
        };
      }

      console.log(data?.error);

      return { message: data?.message, errorMessage: data?.error?.message };
    } catch (err) {
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      if (isObject(action.payload)) {
        state.notifications.unshift({
          id: uniqueId("notif"),
          show: true,
          type: action.payload?.type ?? "info",
          title: action.payload?.title ?? "System Notification",
          message: action.payload.message,
          detailsUrl: action.payload?.detailsUrl,
        });
        return;
      }

      state.notifications.unshift({
        id: uniqueId("notif"),
        show: true,
        type: "info",
        title: "System Notification",
        message: action.payload,
        detailsUrl: null,
      });
    },
    hideNotification: (state, action) => {
      let notification = state.notifications.find(
        (f) => f.id === action.payload
      );

      notification.show = false;
    },
  },
  extraReducers: (builder) => {
    //
    // Toast Error
    //
    builder.addCase(showNotificationError.pending, (state) => {});
    builder.addCase(showNotificationError.fulfilled, (state, action) => {
      const message =
        action.payload?.message == null && action.payload.errorMessage != null
          ? action.payload.errorMessage
          : action.payload?.message;

      state.notifications.unshift({
        id: uniqueId("notif"),
        show: true,
        type: "error",
        title: "Error Notification",
        message,
        errorMessage: action.payload?.errorMessage,
        detailsUrl: action.payload?.detailsUrl,
      });
    });
    builder.addCase(showNotificationError.rejected, (state) => {});
  },
});

export const { showNotification, hideNotification } = systemSlice.actions;

export default systemSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.system;

export const selectSystem = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
