import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { limitFileName } from "../utils/helper";
import { noop } from "lodash";

function ImageListing({ files, isReadonly, onRemoveFile, onOpenPreview }) {
  return (
    <>
      {files.map((file, index) => {
        let limitedName = limitFileName(file.name);

        return (
          <Col key={index} className="my-2" xs="6" md="6">
            <Card>
              <Card.Body>
                {!isReadonly && (
                  <div className="remove-button">
                    <i
                      id={`remove${index}`}
                      className="fas fa-times sol-clickable text-muted"
                      onClick={onRemoveFile(file)}
                    />
                    {/* <ToolTipFx target={`remove${index}`}>Remove</ToolTipFx> */}
                  </div>
                )}
                <Row>
                  <Col
                    className="p-2x d-flex flex-row justify-content-center"
                    md="12"
                    xs="12"
                  >
                    <img
                      id={`image${index}`}
                      src={file.preview}
                      className="img sol-clickable thumb-img p-2"
                      alt={file.name}
                      onClick={() => onOpenPreview(file)}
                    />

                    {/* <ToolTipFx target={`image${index}`}>Open preview</ToolTipFx> */}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <span className="text-muted small">{limitedName}</span>
                    <span className="font-weight-bold">
                      {" "}
                      ({Math.round(file.size / 1024)} kb)
                    </span>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        );
      })}
    </>
  );
}

ImageListing.propTypes = {
  files: PropTypes.array,
  isReadonly: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ImageListing.defaultProps = { onRemoveFile: noop, onOpenPreview: noop };

export default ImageListing;
