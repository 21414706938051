import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useStateWithRef from "hooks/useStateWithRef";
import RcNavBar from "./RcNavBar";
import { useAppTheme } from "hooks";
import "./rc-navbar-fx1.scss";

function RcNavBarFx1({ scrollTriggerValue, ...rest }) {
  const { currentTheme } = useAppTheme();
  const navbarTheme = `navbar-fx1-${currentTheme}`;
  const { stateValue, stateRefValue, setStateValue } =
    useStateWithRef(`navbar-default `);

  const ANIMATED_STICKY = `navbar-animated-sticky ${navbarTheme}`;

  useEffect(() => {
    document.addEventListener("scroll", scrollFn, { passive: true });
    checkIfPageWasScrolled();

    return () => {
      document.removeEventListener("scroll", scrollFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.pageYOffset > scrollTriggerValue) {
      setStateValue(ANIMATED_STICKY);
      return;
    }

    setStateValue("navbar-default");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const checkIfPageWasScrolled = () => {
    if (window.pageYOffset > scrollTriggerValue) {
      setStateValue(ANIMATED_STICKY);
    }
  };

  const scrollFn = (e) => {
    if (
      window.pageYOffset > scrollTriggerValue &&
      stateRefValue !== ANIMATED_STICKY
    ) {
      setStateValue(ANIMATED_STICKY);
    } else {
      setStateValue("navbar-default");
    }
  };

  return <RcNavBar {...rest} navBarCss={stateValue} />;
}

RcNavBarFx1.propTypes = {
  scrollTriggerValue: PropTypes.number,
};

RcNavBarFx1.defaultProps = { scrollTriggerValue: 30 };

export default RcNavBarFx1;
