const navBarMenus = {
  menus: [
    // {
    //   id: 1,
    //   label: "Home",
    //   url: "/home",
    // },
    {
      id: 2,
      label: "Services",
      type: "parent",
      subMenus: [
        {
          id: 31,
          label: "Termite Control Solution",
          url: "home#service1",
        },
        {
          id: 32,
          label: "Pest Control Solution",
          url: "home#service2",
        },
        {
          id: 33,
          label: "Other Pest Control Services",
          url: "home#service3",
        },
      ],
    },
    {
      id: 3,
      label: "About Us",
      url: "home#about-us",
    },
    {
      id: 4,
      label: "Contact Us",
      url: "home#contacts",
    },
    {
      id: 5,
      label: "Location",
      url: "home#location",
    },
    {
      id: 6,
      label: "Gallery",
      url: "home#gallery",
    },
  ],
};

export default navBarMenus;
