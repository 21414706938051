export const introCarouselData = [
  // {
  //   image: "images/home/carousel/hero1.jpg",
  //   alt: "Image 1",
  // },
  {
    image: "images/home/carousel/hero3.webp",
    alt: "Image 2",
  },
  {
    image: "images/home/carousel/hero2.webp",
    alt: "Image 3",
  },
  {
    image: "images/home/carousel/hero4.webp",
    alt: "Image 4",
  },
];
