/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import FormSelectField from "components/ui/formikFields/formSelect/FormSelectField";
import { RcButton } from "components/ui/rcButton";
import { useNavigate } from "react-router-dom";
//import * as QuotationSlice from "store/modules/quotation-slice";
import { useSelector } from "react-redux";
import useFastField from "components/ui/formikFields/hooks/useFastField";
import * as CommonDataSlice from "store/modules/commonDataSlice";
import { FormInput, FormWatch } from "components/ui/formikFields";
import { Col } from "react-bootstrap";
import InputField from "components/ui/formFields/inputField/InputField";
import { PlainText } from "components/ui/formFields";

function AddServiceInput({ containerCss, onAdd }) {
  const navigate = useNavigate();

  const [subCategoryFinal, setSubCategoryFinal] = useState([]);

  const { field: serviceTypeIdField, helpers: serviceTypeHelpers } =
    useFastField({ name: "serviceTypeId" });

  const { field: serviceIdField, helpers: serviceNameHelpers } = useFastField({
    name: "serviceId",
  });

  //const quoteState = useSelector(QuotationSlice.selectQuotation);

  const { productServicesQuoteData } = useSelector(
    CommonDataSlice.selectCommonData
  );

  useEffect(() => {
    if (serviceTypeIdField.value == null) return;
    setSubCategoryFinal(serviceTypeIdField.value.subCategories);
  }, [serviceTypeIdField.value]);

  useEffect(() => {
    return () => {
      serviceTypeHelpers.setValue(null, true);
      serviceNameHelpers.setValue(null, true);
    };
  }, []);

  const handleChangeOverride = () => {
    serviceNameHelpers.setValue(null, true);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <section className={`p-2 ${containerCss}`}>
      <Row>
        <FormSelectField
          name="serviceTypeId"
          label="Service Type"
          //options={quoteState.serviceTypeLookups}
          options={productServicesQuoteData}
          showRequiredLabel={true}
          closeMenuOnSelect={true}
          xs={12}
          onChangeOverride={handleChangeOverride}
        />
      </Row>
      <Row>
        <FormSelectField
          name="serviceId"
          label="Service Name"
          options={subCategoryFinal}
          showRequiredLabel={true}
          closeMenuOnSelect={true}
          xs={12}
        />
      </Row>
      <Row>
        <Col>
          <PlainText
            type="html"
            label="Description"
            value={serviceIdField.value?.description ?? "--"}
          />
        </Col>
      </Row>
      <Row>
        <div>
          <RcButton variant="pill-primary me-2" onClick={onAdd}>
            Add
          </RcButton>
          <RcButton variant="pill-secondary" onClick={handleCancel}>
            Cancel
          </RcButton>
        </div>
      </Row>
    </section>
  );
}

AddServiceInput.propTypes = {
  containerCss: PropTypes.string,
  onAdd: PropTypes.func,
};

AddServiceInput.defaultProps = {};

export default AddServiceInput;
