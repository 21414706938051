import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

function Label({ label, labelBold, showRequiredLabel }) {
  return (
    <>
      {label && (
        <>
          <Form.Label
            className={`label-field-style ${
              labelBold ? "label-field-stylefw-bold" : "fw-normal"
            }`}
          >
            {label}
          </Form.Label>
          {showRequiredLabel && <span className="text-danger ms-1">*</span>}
        </>
      )}
    </>
  );
}

Label.propTypes = {
  label: PropTypes.string,
  labelBold: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
};

Label.defaultProps = { showLabel: true };

export default Label;
