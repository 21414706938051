export const ourClientsData = [
  {
    id: 1,
    image: "images/clients/client1.webp",
    alt: "Client 1",
  },
  {
    id: 2,
    image: "images/clients/client2.webp",
    alt: "Client 2",
  },
  {
    id: 3,
    image: "images/clients/client3.webp",
    alt: "Client 3",
  },
  {
    id: 4,
    image: "images/clients/client4.webp",
    alt: "Client 4",
  },
];
