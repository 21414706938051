import { Image } from "react-bootstrap";
import "./notFound.scss";
import { RcButton } from "components/ui/rcButton";
import { useNavigate } from "react-router-dom";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";

function Page404() {
  const navigate = useNavigate();

  const handleGoBack = () => navigate("/");

  return (
    <section className="page-404-wrapper text-center">
      <RcHelmet
        title="Abbatoir Pest Control"
        description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution"
        keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination"
      />
      <h1 className="rca-heading1">Oops!</h1>
      <h2 className="rca-heading5">Page Not Found</h2>
      <Image src="/images/misc/page-404.webp" />
      <h5>The page you've requested is not available.</h5>
      <div>
        <RcButton
          variant="pill-primary"
          size="lg"
          containerCss="my-4"
          onClick={handleGoBack}
        >
          Go Back Home
        </RcButton>
      </div>
    </section>
  );
}

export default Page404;
