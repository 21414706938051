import React from "react";
import PropTypes from "prop-types";
import {
  CLIENT_EMAIL,
  FOOTER_PHONE1,
  FOOTER_PHONE2,
  LANDLINE_PHONE,
  OPENING_DAY_TIME,
} from "constants/appConstants";

function PhoneNumbers({ containerCss }) {
  return (
    <section className={`phone-numbers ${containerCss}`}>
      <h4>Talk to us today</h4>
      <p className="mb-4">
        Office hours: <span className="fw-600">{OPENING_DAY_TIME}</span>
      </p>
      <p>
        <i class="fas fa-phone-volume me-3"></i>
        <a href={`tel:${LANDLINE_PHONE}`}>{LANDLINE_PHONE}</a>
      </p>
      <p>
        <i class="fas fa-mobile-alt me-3"></i>
        <a href={`tel:${FOOTER_PHONE1}`}>{FOOTER_PHONE1}</a>
      </p>
      <p>
        <i class="fas fa-mobile-alt me-3"></i>
        <a href={`tel:${FOOTER_PHONE2}`}>{FOOTER_PHONE2}</a>
      </p>
      <p>
        <i class="fas fa-envelope me-3"></i>
        <a href={`mailto:${CLIENT_EMAIL}`} className="text-break">
          {CLIENT_EMAIL}
        </a>
      </p>
    </section>
  );
}

PhoneNumbers.propTypes = {
  containerCss: PropTypes.string,
};

PhoneNumbers.defaultProps = {};

export default PhoneNumbers;
