import React from "react";
import PropTypes from "prop-types";
import { RcButton } from "components/ui/rcButton";
import { ReactComponent as PlusSvg } from "assets/images/quote/plus-circle.svg";
import { useNavigate } from "react-router-dom";

function AddService({ containerCss }) {
  const navigate = useNavigate();

  const handleAddService = () => {
    navigate("add-service");
  };

  return (
    <section
      className={`d-flex flex-column justify-content-center align-items-center h-100 ${containerCss}`}
    >
      <RcButton variant="outline-pill-secondary" onClick={handleAddService}>
        <PlusSvg />
        &nbsp;&nbsp;Add a Service
      </RcButton>
    </section>
  );
}

AddService.propTypes = {
  containerCss: PropTypes.string,
};

AddService.defaultProps = {};

export default AddService;
