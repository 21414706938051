import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Card, Col, Row } from "react-bootstrap";
import IconProvider from "../utils/IconProvider";
import { limitFileName } from "../utils/helper";

function FileListing({ files, onRemoveFile }) {
  return (
    <>
      {files.map((file, index) => {
        const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
        const icon = IconProvider().getIcon(ext);
        let limitedName = limitFileName(file.name);

        return (
          <Col key={index} className="my-2" xs="6" md="4">
            <Card>
              <Card.Body>
                <div className="remove-button">
                  <i
                    id={`remove${index}`}
                    className="fas fa-times sol-clickable text-muted"
                    onClick={onRemoveFile(file)}
                  />
                  {/* <ToolTipFx target={`remove${index}`}>Remove</ToolTipFx> */}
                </div>
                <Row className="w-100 justify-content-center">
                  <Col className="p-2" md="12" xs="6">
                    <>
                      <i
                        id={`download${index}`}
                        className={`${icon} fa-7x sol-clickable`}
                      />
                      {/* {file.type === "init" && (
                    <ToolTipFx target={`download${index}`}>Download</ToolTipFx>
                  )} */}
                    </>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <span className="text-muted small">{limitedName}</span>
                    <span className="font-weight-bold">
                      {" "}
                      ({Math.round(file.size / 1024)} kb)
                    </span>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        );
      })}
    </>
  );
}

FileListing.propTypes = {
  files: PropTypes.array,
  onRemoveFile: PropTypes.func,
};

FileListing.defaultProps = {
  files: [],
  onRemoveFile: noop,
};

export default FileListing;
