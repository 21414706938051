import { Navigate, Route } from "react-router-dom";
import DemoPage from "pages/demo/DemoPage";
import ButtonPage from "pages/demo/buttons/ButtonPage";
import FramerPage from "pages/demo/framer/FramerPage";
import NavBarPage from "pages/demo/navBar/NavBarPage";
import HomePage from "pages/home/HomePage";
import DataTablePage from "pages/demo/table/DataTablePage";
import QuotationPage from "pages/quotation/QuotationPage";
import AddServiceForm from "components/containers/quotation/services/AddServiceForm";
import PrivacyPage from "pages/privacy/PrivacyPage";
import RequestCompletedPage from "pages/requestCompleted/RequestCompletedPage";
import CookiePolicyPage from "pages/cookiePolicy/CookiePolicyPage";

const mainRoutes = () => {
  return (
    <>
      <Route index element={<HomePage />} />
      <Route path="home" element={<HomePage />} />
      <Route path="quotation" element={<QuotationPage />}>
        <Route path="add-service" element={<AddServiceForm />} />
      </Route>
      <Route
        path="request-quotation-completed"
        element={<RequestCompletedPage />}
      />
      <Route path="privacy-policy" element={<PrivacyPage />} />
      <Route path="cookie-policy" element={<CookiePolicyPage />} />
      <Route path="demo" element={<DemoPage />}>
        <Route path="buttons" element={<ButtonPage />} />
        <Route path="navbar" element={<NavBarPage />} />
        <Route path="framer-motion" element={<FramerPage />} />
        <Route path="data-table" element={<DataTablePage />} />
      </Route>
      {/* <Route path="about" element={<AboutUsView />} />
        <Route path="contacts" element={<ContactUsView />} />
        <Route
          path="demo"
          element={
            <ProtectedRoute>
              <DemoView />
            </ProtectedRoute>
          }
        /> */}
      <Route path="*" element={<Navigate to="pages/error-404" />} />
    </>
  );
};

export default mainRoutes;
