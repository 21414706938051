import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";

function RcTooltip({ message, selector, place }) {
  return (
    <Tooltip anchorSelect={`.${selector}`} place={place}>
      {message}
    </Tooltip>
  );
}

RcTooltip.propTypes = {
  message: PropTypes.string,
  place: PropTypes.string,
};

RcTooltip.defaultProps = { place: "bottom" };

export default RcTooltip;
