import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { ReactComponent as TrashSvg } from "assets/images/feather/trash-2.svg";
import { RcButton } from "components/ui/rcButton";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import ConfirmModal from "components/ui/formFields/modals/confirmModal/ConfirmModal";

function ServicesGrid({ data, onDeleteClick, containerCss }) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const itemToDeleteRef = useRef();

  const handleConfirmHide = () => setShowConfirmDelete(false);

  const handleConfirm = () => {
    setShowConfirmDelete(false);
    onDeleteClick(itemToDeleteRef.current);
  };

  const handleDeleteClick = (e) => {
    itemToDeleteRef.current = e;
    setShowConfirmDelete(true);
  };

  return (
    <section className={containerCss}>
      <ConfirmModal
        title="Delete Confirmation"
        isOpen={showConfirmDelete}
        onHide={handleConfirmHide}
        onOkButton={handleConfirm}
      >
        Are you sure you want to delete this item?
      </ConfirmModal>
      <Table className="service-table" responsive>
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Service Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((o) => (
            <tr>
              <td>{o.serviceName}</td>
              <td>{o.serviceType}</td>
              <td>
                <RcButton
                  variant="light"
                  containerCss="delete-button"
                  onClick={() => handleDeleteClick(o)} //() => onDeleteClick(o)}
                >
                  <TrashSvg className="trash-bin" />
                </RcButton>
                <RcTooltip message="Delete service" selector="delete-button" />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
}

ServicesGrid.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
  onDeleteClick: PropTypes.func,
};

ServicesGrid.defaultProps = {};

export default ServicesGrid;
